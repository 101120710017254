import { parse, string } from 'valibot';

/** The client site id for google reCAPTCHA */
export const GOOGLE_RECAPTCHA_SITE_KEY = parse(
  string(),
  import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY,
);

/** Service ID of the service through which emails should be sent. */
export const EMAILJS_SERVICE_ID = parse(
  string(),
  import.meta.env.VITE_EMAILJS_SERVICE_ID,
);

/** Template ID of the email. */
export const EMAILJS_TEMPLATE_ID = parse(
  string(),
  import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
);

/** Used to identify the account on https://emailjs.com */
export const EMAILJS_PUBLIC_KEY = parse(
  string(),
  import.meta.env.VITE_EMAILJS_PUBLIC_KEY,
);

// Firebase
export const FIREBASE_API_KEY = parse(
  string(),
  import.meta.env.VITE_FIREBASE_API_KEY,
);

export const FIREBASE_PROJECT_ID = parse(
  string(),
  import.meta.env.VITE_FIREBASE_PROJECT_ID,
);

export const FIREBASE_AUTH_DOMAIN = parse(
  string(),
  import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
);

export const FIREBASE_APP_ID = parse(
  string(),
  import.meta.env.VITE_FIREBASE_APP_ID,
);

export const FIREBASE_MEASUREMENT_ID = parse(
  string(),
  import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
);

export const FIREBASE_MESSAGING_SENDER_ID = parse(
  string(),
  import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
);

export const FIREBASE_STORAGE_BUCKET = parse(
  string(),
  import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
);
